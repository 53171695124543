
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Actions } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'sign-up',
  components: {
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const validationLoading = ref(false)
    const validationError = ref('')
    onMounted(() => {
      const hash = router.currentRoute.value.params.id
      const payload = { hash: hash }
      validationLoading.value = true
      store.dispatch(Actions.API_ACCOUNT_EMAIL_SUBMIT_ACTIVATE, payload).then(() => {
        validationLoading.value = false
      }).catch((e) => {
        console.log(e)
        validationError.value = e.data.error.message
        validationLoading.value = false
      })
    })

    return {
      validationLoading,
      validationError
    }
  }
})
